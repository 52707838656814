@media (max-width: 479.97px) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 480px) and (max-width: 767.97px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 768px) and (max-width: 1023.97px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}

* { font-family: 'Microsoft JhengHei'; }